.actions-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    .progress-button{
        height: 34px;
        border-radius: 4px;
    }
    .C-ProgressBar{
        margin-top:0px;
    }
}
