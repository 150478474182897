.filters-container{
    display:flex;
    flex-direction:row
}
.filter-radio-button{
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin-right:50px;
    .radio-button-name{
        font-size:12px;
        font-weight:bold
    }
}