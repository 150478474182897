@use "~@ag-grid-community/styles" as ag;
@import '@fortawesome/fontawesome-free/scss/solid.scss';

// Colors
$white: #ffffff;
$gray-light: #f2f2f2;
$gray-medium: #d6d6d6;
$gray: #b3b4b3;
$gray-dark: #666666;
$black: #000000;
$yellow-light: #fff8e5;
$yellow-dark: #fde30b;
$orange-light: #fcdfca;
$orange-dark: #e86900;
$red-dark: #cc0000;
$blue-light: #cdd9f4;
$blue-dark: #366cd9;
$green: #36d944;
$green-light: #3bd936;

// Variables
$border-color: $gray-medium;
$selected-color: $blue-dark;
$spacing-unit: 16px;
$font-size: 12px;
$icons-font-family: 'Material Icons';

@include ag.grid-styles((
  theme: material,
  header-height: $spacing-unit * 3,
  row-height: $spacing-unit * 1.5,
  material-primary-color: $selected-color,
  material-accent-color: $selected-color,
  header-background-color: $white,
  header-cell-hover-background-color: transparent,
  selected-row-background-color: $selected-color,
  foreground-color: $gray-dark,
  secondary-foreground-color: $gray-dark,
  checkbox-checked-color: $blue-dark,
  checkbox-unchecked-color: $gray-dark,
  checkbox-indeterminate-color: $blue-dark,
  border-color: $border-color,
  border-radius: 0,
  font-size: $font-size,
  icon-size: $font-size,
  cell-widget-spacing: calc($spacing-unit / 2),
  row-group-indent-size: $spacing-unit * 1.5,
));

@mixin ag-grid-cell-validation-style($right, $paddingRight) {
  &.ag-grid-cell-error {
    background-color: $red-dark;
    color: $white;

    &.ag-cell-text-align-right, &.ag-right-aligned-cell {
      padding-right: $paddingRight;
    }

    &::after {
      font-size: $font-size * 1.5;
      font-family: $icons-font-family;
      content: '\e000';
      position: absolute;
      right: $right;
      color: $white;
      top: 1px;
    }

    .ag-react-container a {
      color: $white;
    }
  }

  &.ag-grid-cell-warning {
    &:not(.ag-grid-cell-error) {
      background-color: $orange-light;

      &.ag-cell-text-align-right, &.ag-right-aligned-cell {
        padding-right: $paddingRight;
      }

      &::before {
        font: var(--fa-font-solid);
        content: "\f071";
        position: relative;
        right: $right;
        color: $orange-dark;
        font-size: $font-size * 1;
      }
    }
  }
  &.ag-grid-check-mark {
    &:not(.ag-grid-cell-error) {
      // background-color: $green-light;

      &.ag-cell-text-align-right, &.ag-right-aligned-cell {
        padding-right: $paddingRight;
      }

      &::before {
        font: var(--fa-font-solid);
        content: "\f00c";
        position: relative;
        right: $right;
        color: $green;
        font-size: $font-size * 1;
      }
    }
  }

  &.ag-grid-cell-audit {
    &:not(.ag-grid-cell-error):not(.ag-grid-cell-warning) {
      background-color: $yellow-light;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        display: block;
        border-left: 8px solid transparent;
        border-top: 8px solid $black;
      }
    }
  }
} // @mixin ag-grid-cell-validation-style

.ag-theme-material.ag-grid-react {
  border: 1px solid $border-color;

  .ag-row-hover .ag-cell {
    background-color: $blue-light;
  }

  .ag-picker-field-wrapper {
    border: 1px solid $border-color;
  }

  //.ag-ltr is required for specificity
  .ag-ltr {
    // Icons
    .material-icons {
      font-size: $font-size * 1.5;
      font-weight: bold;
    }
    .ag-icon {
      font-size: $font-size * 1.5;
    }

    // Header
    .ag-header {
      .ag-header-cell {
        border-right: 1px solid $border-color;
        border-bottom: 2px solid $gray-dark;
        padding: 4px 2px 2px 4px;
        align-items: flex-start;
        .ag-header-cell-label {
          align-items: flex-start;
        }
        .ag-header-cell-text {
          font-size: $font-size;
          white-space: normal;
        }
        .ag-header-cell-resize {
          width: 4px;
          right: 0;
        }
        .ag-header-select-all {
          margin-top: 1px;
          margin-right: $spacing-unit;
          margin-left: 4px;
        }
      }
      .ag-header-group-cell {
        padding: 4px 2px 2px 4px;
        background-color: $gray-dark;
        border-right: 1px solid $white;
        color: $white;
        .ag-header-group-cell-label {
          align-items: baseline;
        }
      }
      // Highlighted column from search
      .focusHeader {
        background-color: $selected-color !important;
        color: $white;
        .ag-icon {
          color: $white;
        }
        a {
          color: $white;
        }
      }
      //Keep the header left aligned for the right aligned cells
      .ag-right-aligned-header {
        .ag-cell-label-container {
          flex-direction: row-reverse;
        }
        .ag-header-cell-label {
          flex-direction: row;
        }
      }
    } // .ag-header

    // Pinned top row
    .ag-floating-top {
      border: 0;
    }

    // Pinned left columns
    .ag-pinned-left-header {
      border: 0;
      .ag-header-cell {
        border-right: 2px solid $gray-dark;
      }
    }
    .ag-pinned-left-floating-top {
      .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right) {
        border-right: 2px solid $white;
      }
    }
    .ag-pinned-left-cols-container {
      .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right) {
        border-right: 2px solid $gray-dark;
      }
      .ag-row-selected {
        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right) {
          border-right: 2px solid $white;
        }
      }
      .ag-row-focus {
        .ag-cell:first-child {
          border-left: 5px solid $blue-dark;
          padding: 2px 8px 0 0;
          &.ag-cell-focus {
            padding: 0 8px 0 0;
          }
        }
        &.ag-row-selected {
          .ag-cell:first-child {
            border-left: 5px solid $white;
          }
        }
      }
      // Thin border for pinned range
      .ag-cell-range-selected {
        &:not(.ag-cell-focus),
        &.ag-cell-focus {
          &:not(.ag-cell-range-single-cell) {
            &.ag-cell-range-left {
              border-left: 1px solid $blue-dark;
              padding-left: 4px;
            }
          }
        }
      }
    } // .ag-pinned-left-cols-container

    // Cells
    .ag-cell {
      // background-color: $gray-light;
      border-right: 1px solid $border-color;
      padding: 0 8px 0 4px;

      &.ag-cell-edit-user,
      &.ag-cell-not-inline-editing {
        :not(.ag-cell-focus) {
          // Fix old-ag-grid css giving row double pixel borders
          border-bottom-width: 0px; // Attempt to fix old ag-grid css adding doubled border
        }
      }

      .ag-cell-wrapper {
        // Fixes old ag-grid css shifting our cell's content down
        align-items: flex-start
      }

      &:not(.ag-cell-inline-editing) {
        @include ag-grid-cell-validation-style(
          calc($spacing-unit / 4),
          $spacing-unit * 1.5
        ); // Do not apply validation styles when editing a cell
      }

      // Editable cells
      &.ag-cell-edit-user {
        background-color: $white;
        font-weight: bold;
        color: $black;
      }

      // Cell focus state
      &.ag-cell-focus {
        border: 2px solid $blue-dark;
        padding: 0 7px 0 3px; // take extra border pixel off padding
        line-height: 20px;
        &:not(.ag-cell-inline-editing) {
          @include ag-grid-cell-validation-style(
            calc($spacing-unit / 4) - 1,
            ($spacing-unit * 1.5) - 1
          ); // Do not apply validation styles when editing a cell
        }
      }

      // Cell edit state
      &.ag-cell-inline-editing {
        padding: 0;
        height: $spacing-unit * 1.5;
        background-color: $white;
        input {
          padding-left: 4px;
          padding-bottom: 0;
        }
      }

      // Range selection
      &.ag-cell-range-selected {
        &:not(.ag-cell-focus) {
          &:not(.ag-cell-range-single-cell) {
            background-color: $gray-medium;
            border-right: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;
            &.ag-cell-range-top {
              border-top-color: $blue-dark;
            }
            &.ag-cell-range-right {
              border-right-color: $blue-dark;
            }
            &.ag-cell-range-bottom {
              border-bottom-color: $blue-dark;
            }
            &.ag-cell-range-left {
              border-left-color: $blue-dark;
            }
          }
        }
      }

      // Change Indicator
      &.ag-cell-alerts {
        &:not(.ag-grid-cell-error) {
          &:not(.ag-grid-cell-warning) {
            background-color: $yellow-light;
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              border-left: 8px solid transparent;
              border-top: 8px solid $black;
            }
          }
        }
      }

      // Preview Changes
      &.affected {
        border: 2px dashed $yellow-dark;
        padding: 0 7px 0px 3px; // take extra border pixel off padding
        line-height: 21px;
      }

      &.ag-cell-text-align-right {
        text-align: right;
      }

      .ag-fill-handle {
        right: -1px;
      }
      //Down arrow icon for dropdown cells
      &.ag-grid-cell-dropdown {
        &.ag-cell-edit-user{
          &:not(.ag-cell-inline-editing){       
            &::before {
              font-size: $font-size;
              font-family: $icons-font-family;
              content: "\e5c5";
              position: absolute;
              cursor: pointer;
              right: calc($spacing-unit / 4);
            }
            @include ag-grid-cell-validation-style($spacing-unit, 0px)
          }
        }
      }
    } // .ag-cell

    // Row selection
    .ag-row-selected {
      .ag-cell {
        background-color: $selected-color;
        color: $white;
        &.ag-cell-focus {
          border: 2px solid $white;
          background-color: $selected-color;
          color: $white;
        }
        &.ag-cell-inline-editing {
          background-color: $white;
        }
        a {
          color: $white;
        }
        .ag-checked::after {
          color: $white;
        }
        .ag-group-contracted,
        .ag-group-expanded {
          color: $white;
        }
      }
    }

    // Status Bar
    .ag-status-bar {
      padding-right: $spacing-unit;
      padding-left: $spacing-unit;
      line-height: 1.5;
      font-weight: normal;
      color: $gray-dark;
      font-size: $font-size;

      .ag-status-name-value {
        padding-top: calc($spacing-unit / 2);
        padding-bottom: calc($spacing-unit / 2);
      }
    }

    // No pink highlight on copy and paste
    .ag-cell-highlight {
      background-color: $white !important;
    }

    // Enterprise only Select with better performance
    .ag-rich-select {
      .ag-rich-select-list {
        height: 100%;
      }
      .ag-rich-select-row-selected {
        color: $white;
      }
    }
  } // .ag-ltr

  // Alternate styles for uneditable tables
  // &.non-editable .ag-row:not(.ag-row-selected) .ag-cell {
  //   background-color: $white !important;
  // }
} // .ag-theme-material

// Autocomplete Editor
.ag-cell-editor-autocomplete {
  background-color: $white !important;
  > div:hover:not(.group),
  > div.selected {
    background: $gray-dark !important;
    color: $white !important;
  }
  > div > span {
    font-family: 'Roboto' !important;
    font-size: $font-size !important;
  }
}

.ag-grid-dropdown-hover > option:hover {
  background: $gray-dark !important;
  color: $white !important;
}