.side-nav-container {
    background-color: black;
    height:100%;
}
.side-nav-header {
    background-color: rgb(204, 0, 0);
    .C-GridContainer {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 5px;
    }
    pointer-events: none;

}
.C-SideNav__NavigationItem{
    color: #666;
}
.side-nav-link {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.side-nav-footer {
    background-color: black;
    font-size: 12px;
    text-align: right;
    
}