.feedback {
    color: #333 !important;
    h4 {
        font-weight: 700!important;
    }
    .C-FormField__FieldInfo {
        display: none;
    }
    .icons{
        margin:0;
        padding: 0;
        button {
            margin-right: 24px;
            background: #fff;
            border: none;
            color: #333;
            border-radius: 15px;
            box-shadow: none;
            &.mad {
                &:hover {
                    background:#fff;
                    color:#f03810;
                }
                &.selected{
                    background: #f03810;
                    color: #333;
                }
            }
            &.sad {
                &:hover {
                    background: #fff;
                    color:#f9841b;
                }
                &.selected{
                    background: #f9841b;
                    color: #333;
                }
            }
            &.meh {
                &:hover {
                    background: #fff;
                    color:#fed573;
                }
                &.selected{
                    background: #fed573;
                    color: #333;
                }
            }
            &.good {
                &:hover {
                    background: #fff;
                    color:#57d441;
                }
                &.selected{
                    background: #57d441;
                    color: #333;
                }
            }
            &.great {
                &:hover {
                    background: #fff;
                    color:#008000;
                    
                }
                &.selected{
                    background: #008000;
                    color: #333;
                }
            }
            
        }
    }
}

.feedback-view {
    .capitalize{
        text-transform: capitalize;
    }
    .icon {
        font: var(--fa-font-solid);
        font-size: 50px;
        &.angry:before {
            content: "\f556";
            color:#f03810;
        }
        &.laughbeam:before {
            content: "\f59a";
            color:#008000;
        }
        &.frownopen:before {
            content: "\f57a";
            color:#f9841b;
        }
        &.meh:before {
            content: "\f11a";
            color:#fed573;
        }
        &.smile:before {
            content: "\f118";
            color:#57d441;
        }
    }

}